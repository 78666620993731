import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function Protected({ children }) {

  const[isLoggedIn, setisLoggedIn] = useState(localStorage.getItem("styli_fileUpload") !== null ? true : false);

  const history = useHistory();
  return (
    <div className="d-flex">
      <div className="sidenav-bg" style={{ width: "8%", height: "100vh" }}></div>
      <div className="px-2 protected-bg">
      {isLoggedIn ? children : history.push("/")}
      </div>
    </div>
  );
}