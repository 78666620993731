import React, { useEffect, useState } from "react";
import { ReactSpreadsheetImport } from "subhasmitalmg-react-spreadsheet-import";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getEnvvariable } from "../util/env";
import { getToken } from "../util/helper";

const envConfig = getEnvvariable();

const apiUrl = envConfig.REACT_APP_API_URL;
// const apiUrl = "https://sup-portal-service-qa-nrb4qa3iua-df.a.run.app";
// const apiUrl = "http://localhost:8080";

export default function FileUploads() {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const autoMapSelectValues = true;
  const OPTIONS = [
    { value: "PASS", label: "PASS" },
    { value: "CONDITIONAL PASS", label: "CONDITIONAL PASS" },
    { value: "FAIL", label: "FAIL" },
    { value: "PENDING", label: "PENDING" },
  ];
  const OPTIONSRS = [
    { value: "RECEIVED", label: "RECEIVED" },
    { value: "NOT RECEIVED", label: "NOT RECEIVED" },
  ];
  const OPTIONSC = [
    { value: "IN", label: "IN" },
    { value: "CN", label: "CN" },
  ];
  const OPTIONSIC = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
  ];
  const DownloadDbData = async () => {
    try {
      let token = getToken();
      const response = await axios.post(
        apiUrl + "/fileuploader/preproductionlist",
        // "http://localhost:8080/fileuploader/preproductionlist",
        {},
        {headers: {Authorization: `${token}`}}
      );
      const filelink = response.data.filelink;

      const link = document.createElement("a");
      link.href = filelink;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("styli_fileUpload");
        history.push("/");
        window.location.reload(true);
      }
      else if (error.response && error.response.status === 500) {
        console.error("Internal Server Error:", error.response.data);
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  };
  const onClose = () => {
    setIsOpen(false);
    history.push("/bulkupload");
  };
  const onDownload = (data, file) => {
    const allData = data.all;
    const invalidRows = [];
    allData.filter((item) => {
      if (item?.__errors) {
        invalidRows.push(item);
      }
    });

    // const numberOfInvalidRows = invalidRows.length;

    const output = [];
    const headerRow = [
      "DPO date",
      "Sourcing Country",
      "PO Number",
      "Option Id",
      "Vendor Manager",
      "Sample Iteration Count",
      "Sample Receive Status",
      "Image QC",
      "Quantity QC",
      "FIT QC",
      "Final Sample QC Status",
      "Material QC",
      "FPO Date",
      "Error Message",
    ];
    output.push(headerRow);

    invalidRows.forEach((item) => {
      const rowData = [
        item["dpo_date"],
        item["sourcing_country"],
        item["po_number"],
        item["option_id"],
        item["vendor_manager"],
        item["sample_iteration_count"],
        item["sample_receive_status"],
        item["image_qc"],
        item["quantity_qc"],
        item["fit_qc"],
        item["final_sample_qc_status"],
        item["material_qc"],
        item["fpo_date"],
        item.__errors
          ? Object.values(item.__errors)
              .map((error) => error.message)
              .join(",")
          : "",
      ];
      output.push(rowData);
    });

    const XLSX = require("xlsx");
    const print = {
      invalidData: output,
    };
    const ws = XLSX.utils.aoa_to_sheet(print.invalidData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Invalid Data");

    XLSX.writeFile(wb, "download_errors_pre.xlsx");
    setIsOpen(true);
  };
  const onSubmit = (data, file) => {
    try {
      console.log("data", data);
      let token = getToken();
      const validdata = data.validData;
      const storedItem = localStorage.getItem('styli_fileUpload');
      const parsedItem = JSON.parse(storedItem);
      const firstname = parsedItem.user.firstname;
      // console.log('Firstname:', firstname);
      const username = firstname;

        axios
            .post(apiUrl + "/fileuploader/uploadpreproductdata", {
          validdata,
          username
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      )
        .then((response) => {
          console.log("API Response:", response.data);
          console.log("Data Updated Successfully");
        })
        .catch((error) => {
          console.error("An error occurred during API request:", error);
        });
      console.log("Data Updated Successfully");
    } catch (error) {
      console.error("An error occurred:", error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("styli_fileUpload");
        history.push("/");
        window.location.reload(true);
      }
    }
    setIsOpen(true);
  };
  var fields = [
    {
      label: "DPO date",
      key: "dpo_date",
      alternateMatches: ["DPO date"],
      fieldType: {
        type: "input",
      },
      example: "2023-12-27",
      validations: [
        {
          rule: "required",
          errorMessage: "DPO date is required",
          level: "error",
        },
      ],
    },
    {
      label: "Sourcing Country",
      key: "sourcing_country",
      alternateMatches: ["Sourcing Country"],
      fieldType: {
        type: "select",
        options: OPTIONSC,
      },
      example: "IN",
      validations: [
        {
          rule: "required",
          errorMessage: "Sourcing Country is required",
          level: "error",
        },
      ],
    },
    {
      label: "PO Number",
      key: "po_number",
      alternateMatches: ["PO Number"],
      fieldType: {
        type: "input",
      },
      example: "70010067",
      validations: [
        {
          rule: "required",
          errorMessage: "PO Number is required",
          level: "error",
        },
        {
          rule: "regex",
          errorMessage: "PO Number should be numeric only",
          value: "^[0-9]+$",
        },
      ],
    },
    {
      label: "Option Id",
      key: "option_id",
      alternateMatches: ["Option Id"],
      fieldType: {
        type: "input",
      },
      example: "7005238902",
      validations: [
        {
          rule: "required",
          errorMessage: "Option Id is required",
          level: "error",
        },
        {
          rule: "regex",
          errorMessage: "Option Id should be numeric only",
          value: "^[0-9]+$",
        },
      ],
    },
    {
      label: "Vendor Manager",
      key: "vendor_manager",
      alternateMatches: ["Vendor Manager"],
      fieldType: {
        type: "input",
      },
      example: "RAJESH",
      validations: [
        {
          rule: "required",
          errorMessage: "Vendor Manager is required",
          level: "error",
        },
      ],
    },
    {
      label: "Sample Iteration Count",
      key: "sample_iteration_count",
      alternateMatches: ["Sample Iteration Count"],
      fieldType: {
        type: "select",
        options: OPTIONSIC,
      },
      example: "1",
      validations: [
        {
          rule: "required",
          errorMessage: "Sample Iteration Count is required",
          level: "error",
        },
      ],
    },
    {
      label: "Sample Receive Status",
      key: "sample_receive_status",
      alternateMatches: ["Sample Receive Status"],
      fieldType: {
        type: "select",
        options: OPTIONSRS,
      },
      example: "NOT RECEIVED",
      validations: [
        {
          rule: "required",
          errorMessage: "Sample Receive Status is required",
          level: "error",
        },
      ],
    },
    {
      label: "Image QC",
      key: "image_qc",
      alternateMatches: ["Image QC"],
      fieldType: {
        type: "select",
        options: OPTIONS,
      },
      example: "PENDING",
      validations: [
        {
          rule: "required",
          errorMessage: "Image QC is required",
          level: "error",
        },
      ],
    },
    {
      label: "Quantity QC",
      key: "quantity_qc",
      alternateMatches: ["Quantity QC"],
      fieldType: {
        type: "select",
        options: OPTIONS,
      },
      example: "PENDING",
      validations: [
        {
          rule: "required",
          errorMessage: "Quantity QC is required",
          level: "error",
        },
      ],
    },
    {
      label: "FIT QC",
      key: "fit_qc",
      alternateMatches: ["Fit QC"],
      fieldType: {
        type: "select",
        options: OPTIONS,
      },
      example: "PENDING",
      validations: [
        {
          rule: "required",
          errorMessage: "FIT QC is required",
          level: "error",
        },
      ],
    },
    {
      label: "Final Sample QC Status",
      key: "final_sample_qc_status",
      alternateMatches: ["Final Sample QC Status"],
      fieldType: {
        type: "select",
        options: OPTIONS,
      },
      example: "PENDING",
      validations: [
        {
          rule: "required",
          errorMessage: "Final Sample QC is required",
          level: "error",
        },
      ],
    },
    {
      label: "Material QC",
      key: "material_qc",
      alternateMatches: ["Material QC"],
      fieldType: {
        type: "select",
        options: OPTIONS,
      },
      example: "PENDING",
      validations: [
        {
          rule: "required",
          errorMessage: "Material QC is required",
          level: "error",
        },
      ],
    },
    {
      label: "FPO date",
      key: "fpo_date",
      alternateMatches: ["FPO date"],
      fieldType: {
        type: "input",
      },
      example: "2023-12-27",
      // validations: [
      //   {
      //     rule: "required",
      //     errorMessage: "FPO date is required",
      //     level: "error",
      //   },
      // ],
    },
  ];
  return (
    <div>
      <ReactSpreadsheetImport
        translations={{
          uploadStep: {
            title: "Pre production file upload",
          },
          validationStep: {
            downloadButtonTitle: "Download Errors",
          },
          alerts:{
            submitSuccess: {
              successMessage: "File submission is done, check the result in History page.It will appear in some minutes.",
            },
          }
        }}
        customTheme={{
          components: {
            UploadStep: {
              baseStyle: {
                dropzoneButton: {
                  bg: "green",
                },
              },
            },
          },
        }}
        rowHook={(data, addError) => {

          const sample_receive_status = data.sample_receive_status;
          const image_qc = data.image_qc;
          const quantity_qc = data.quantity_qc;
          const fit_qc = data.fit_qc;
          const final_sample_qc_status = data.final_sample_qc_status;
          const material_qc = data.material_qc;
          const sourcing_country = data.sourcing_country;
          const fpo_date = data.fpo_date;
          const sample_iteration_count = data.sample_iteration_count;

          if (
            sample_receive_status === "NOT RECEIVED" &&
            image_qc !== "PENDING"
          ) {
            addError("image_qc", {
              message:
                "Image QC cannot be PASS/CONDITIONAL PASS/FAIL if Sample Receive Status is NOT RECEIVED",
              level: "error",
            });
          }
          if (
            sample_receive_status === "NOT RECEIVED" &&
            quantity_qc !== "PENDING"
          ) {
            addError("quantity_qc", {
              message:
                "Quantity QC cannot be PASS/CONDITIONAL PASS/FAIL if Sample Receive Status is NOT RECEIVED",
              level: "error",
            });
          }
          if (sample_receive_status === "NOT RECEIVED" && fit_qc !== "PENDING") {
            addError("fit_qc", {
              message:
                "FIT QC cannot be PASS/CONDITIONAL PASS/FAIL if Sample Receive Status is NOT RECEIVED",
              level: "error",
            });
          }
          if (
            sample_receive_status === "NOT RECEIVED" &&
            material_qc !== "PENDING" && sourcing_country === "IN"
          ) {
            addError("material_qc", {
              message:
                "Material QC cannot be PASS/CONDITIONAL PASS/FAIL if Sample Receive Status is NOT RECEIVED",
              level: "error",
            });
          }
          if (
            (image_qc === "FAIL" ||
              quantity_qc === "FAIL" || 
              fit_qc === "FAIL") &&
            (final_sample_qc_status === "PASS" ||
              final_sample_qc_status === "CONDITIONAL PASS")
          ) {
            addError("final_sample_qc_status", {
              message:
                "Final Sample QC Status cannot be PASS or CONDITIONAL PASS or FAIL if IMAGE QC, QUANTITY QC , FIT QC is FAIL",
              level: "error",
            });
          }
          // if (
          //   fpo_date !== null && fpo_date !== undefined &&
          //   sample_iteration_count === "3" &&
          // (final_sample_qc_status === "PENDING" || final_sample_qc_status === "FAIL")
          // ) {
          //   addError("fpo_date", {
          //     message:
          //       "FINAL SAMPLE QC against the latest iteration cannot be FAIL/PENDING because FPO date is available.",
          //     level: "error",
          //   });
          // }
          // if (
          //   fpo_date !== null && fpo_date !== undefined &&
          //   sample_iteration_count === "3" &&
          // (material_qc === "PENDING" || material_qc === "FAIL")
          // ) {
          //   addError("fpo_date", {
          //     message:
          //       "Material QC against the latest iteration cannot be FAIL/PENDING because FPO date is available.",
          //     level: "error",
          //   });
          // }

          if (
            (sample_receive_status === "NOT RECEIVED" ||
              fit_qc === "FAIL") &&
            (final_sample_qc_status === "PASS" ||
              final_sample_qc_status === "CONDITIONAL PASS" ||
              final_sample_qc_status === "FAIL") && sourcing_country === "IN"
          ) {
            addError("final_sample_qc_status", {
              message:
                "Final Sample QC Status cannot be PASS/CONDITIONAL PASS/FAIL if Sample Receive Status is NOT RECEIVED or FIT QC is FAIL",
              level: "error",
            });
          }

          if ((final_sample_qc_status === "PASS" ||
              final_sample_qc_status === "CONDITIONAL PASS") && (material_qc === "PENDING" ||
              material_qc === "FAIL")) {
            addError("final_sample_qc_status", {
              message:
                "Final Sample QC Status cannot be PASS/CONDITIONAL PASS as Material QC is not PASS/CONDITIONAL PASS",
              level: "error",
            });
          }

          // if ((material_qc === "PASS" ||
          // material_qc === "CONDITIONAL PASS") && (final_sample_qc_status === "PENDING" ||
          // final_sample_qc_status === "FAIL")) {
          //   addError("material_qc", {
          //     message:
          //       "Material QC cannot be PASS/CONDITIONAL PASS as Final Sample QC Status is not PASS/CONDITIONAL PASS",
          //     level: "error",
          //   });
          // }

          return data;
        }}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        onDownload={onDownload}
        DownloadDbData={DownloadDbData}
        autoMapSelectValues={autoMapSelectValues}
        fields={fields}
      />
    </div>
  );
}
