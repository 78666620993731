import "../../assets/css/Header.css";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const Head = () => {
  const storedItem = localStorage.getItem("styli_fileUpload");
  let email = "";
  if(storedItem){
  const parsedItem = JSON.parse(storedItem);
  email = parsedItem.user.email;
  }
  const history = useHistory();

  const handleLogout=()=>{
    localStorage.removeItem("styli_fileUpload");
    // setisLoggedIn(false)
    history.push("/");
    window.location.reload(true);
  }

  return (
    <div className="group-div">
      <div className="group-child35" />
      <div className="group-parent">
        <div className="group-group">
          <img className="group-icon1" alt="" src="/group@2x.png" />
          <div className="rectangle-parent1">
            <div className="group-child36" />
            {/* <div className="vector-parent">
              <img className="vector-icon2" alt="" src="/smallvector@2x.png" />
              <div className="en">EN</div>
            </div> */}
          </div>
        </div>
        <div
          // style={{ border: "1px solid red" }}
          className="trackstylishopcom-parent"
        >
          {/* <div className="trackstylishopcom">{email}</div>
          <img className="ellipse-icon" alt="" src="/ellipse-11@2x.png" /> */}
          <div className="dropdown">
            {/* <button class="dropbtn">Logout</button> */}
            <div className="trackstylishopcom">{email}</div>
          <img className="ellipse-icon" alt="" src="/ellipse-11@2x.png" />
            <div className="dropdown-content-logout">
              {/* <a href="#">Logout</a> */}
              <Button onClick={()=>{handleLogout()}}>Logout</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Head;
