import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "../assets/css/DateFilterContainer.css";
import axios from "axios";
import { getEnvvariable } from "../util/env";
import { getToken } from "../util/helper";

const envConfig = getEnvvariable();

const DateFilterContainer = ({
  propLeft,
  propLetterSpacing,
  propLineHeight,
  options,
  onSelectChange,
  getFilterValue,
  getFilterValueStatus,
  getFilterValueFiletype,
  onDateChange, 
  onDateChangeTo, 
}) => {
  const history = useHistory();
  const groupDivStyle = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  const dateFilterStyle = useMemo(() => {
    return {
      letterSpacing: propLetterSpacing,
      lineHeight: propLineHeight,
    };
  }, [propLetterSpacing, propLineHeight]);

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);
  const [uploadedByOptions, setUploadedByOptions] = useState([]);

  useEffect(() => {
      const fetchUploadedByOptions = async () => {
        try {
          let token = getToken();
          const response = await axios.post(`${envConfig.REACT_APP_API_URL}/fileuploader/fetchuploadedby`, {}, 
                                {headers: {Authorization: `${token}`}});
          const { data } = response.data;
          setUploadedByOptions(data.map(item => item.uploaded_by));
        } catch (error) {
          console.error("Error fetching uploaded by options:", error);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("styli_fileUpload");
            history.push("/");
            window.location.reload(true);
          }
        }
      };
  
      fetchUploadedByOptions();
  }, []);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    if (onSelectChange) {
      onSelectChange(selectedValue);
    }
  };

  const handleLocalDateChange = (date) => {
    setSelectedDate(date);

    if (onDateChange) {
      onDateChange(date);
    }
  };

  const handleLocalDateChangeTo = (date) => {
    setSelectedDateTo(date);

    if (onDateChangeTo) {
      onDateChangeTo(date);
    }
  };


  const renderedOptions = options
    ? options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))
    : null;

  return (
    <div className="selection-filter-parent" style={groupDivStyle}>
      <div className="date-filter">
        <div className="status-div">From Date Filter</div>
        <DatePicker selected={selectedDate} onChange={handleLocalDateChange} dateFormat= "yyyy-MM-dd" className="selectstyle" />
      </div>
      <div className="date-filter-status">
        <div className="status-div">Status</div>
        <div className="select-select-div">
        <select onChange={(e) => getFilterValueStatus(e.target.value)} className="selectstyle">
          <option value="">Select</option>
          <option value="Failed">Failed</option>
          <option value="Success">Success</option>
        </select>
        </div>
      </div>
      <div className="date-filter-upload-by">
        <div className="status-div">Uploaded By</div>
        <div className="select-select-div">
          <select onChange={(e) => getFilterValue(e.target.value)} className="selectstyle">
            <option value="">Select</option>
            {uploadedByOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="date-filter-upload-file-type">
      <div className="status-div">File Type</div>
      <div className="select-select-div">
        <select onChange={(e) => getFilterValueFiletype(e.target.value)} className="selectstyle">
          <option value="">Select</option>
          <option value="1">Pre-Production</option>
          <option value="2">Production</option>
          <option value="3">Post-Production</option>
        </select>
        </div>
      </div>
      <div className="date-filter-to">
        <div className="status-div">To Date Filter</div>
        <DatePicker selected={selectedDateTo} onChange={handleLocalDateChangeTo} dateFormat= "yyyy-MM-dd" className="selectstyle" />
      </div>
    </div>
  );
};

export default DateFilterContainer;
