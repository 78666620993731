export function getToken(){
    try {
      let fileUploadObject = localStorage.getItem('styli_fileUpload');
      let parsedObject = fileUploadObject ? JSON.parse(fileUploadObject) : null;
      let token = parsedObject && parsedObject.token ? parsedObject.token : null;
      return token
    } catch (error) {
      console.error('Error fetching token from localStorage:', error.message);
      return null
    }
  }