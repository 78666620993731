import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import FileUploads from "./components/FileUploads";
import Header from "./components/Header";
import Login from "./components/Login";
import Protected from "./components/Protected";
import React, { useEffect, useState } from "react";
import ProductionSheetUpload from "./components/ProductionSheetUpload";
import PostProductionSheetUpload from "./components/PostProductionSheetUpload";
import Table from "./components/Table";
import BulkUpload from "./components/BulkUpload";
import Dashboard from "./components/Dashboard";
import FileUploadHistory from "./components/FileUploadHistory";
import UserLogin from "./components/UserLogin";

const locale = "en"; // Set the desired locale
const messages = {
  greeting: "Hello, World!",
  "user.login-title": "Login",
  "user.email": "Username",
  "user.login-button": "LOGIN",
};
function App() {
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [apiData, setApiData] = useState([]);

//   useEffect(() => {
//     console.log("login state::::", isLoggedIn);
//   }, [isLoggedIn]);

  return (
    <>
      <IntlProvider locale={locale} messages={messages}>
        <Router>
          <div>
            <Switch>
              <Route path="/fileUploads">
              <Protected>
                <FileUploads />
               </Protected>
              </Route>
              <Route path="/productionsheetupload">
              <Protected>
                <ProductionSheetUpload />
              </Protected>
              </Route>
              <Route path="/postproductionsheetupload">
              <Protected>
                <PostProductionSheetUpload />
               </Protected>
              </Route>
              <Route path="/bulkupload">
              <Protected>
                <BulkUpload />
              </Protected>
              </Route>
              <Route path="/fileuploadhistory">
              {/* <Protected> */}
                <FileUploadHistory tableData={apiData} />
              {/* </Protected> */}
              </Route>
              <Route path="/">
                <UserLogin setisLoggedIn={setisLoggedIn}/>
              </Route>
              {/* <Route path="/header">
              <Protected>
                <Header />
              </Protected>
              </Route> */}
              {/* <Route path="/history">
                <Table tableData={apiData} />
              </Route> */}
              {/* <Route path="/dashboard">
              <Protected>
                <Dashboard />
              </Protected>
              </Route> */}
              {/* <Route path="/login">
                <Login setisLoggedIn={setisLoggedIn} />
              </Route> */}
            </Switch>
          </div>
        </Router>
      </IntlProvider>
    </>
  );
}
export default App;
